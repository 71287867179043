h2.new-user-title {
    text-align: center;
}

.new-user-title-wrapper {
    padding: 20px;
    @media (max-width: 500px) {
        padding: 20px 0;
    }
}

.new-user-help-tip{
    display: block;
    text-align: center;
}

.new-user-import {
    padding: 20px;
}

.new-user-upload-hint {
    color: #104EA1;
    font-weight: 600;
    margin-bottom: 15px;
}

.new-user-seperator {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.new-user-manual-btn {
    background: #104EA1;
    width: 100%;
    height: 50px;
    border-color: #104EA1;
}

.new-user-upload-progress-wrp {
    padding: 20px;
}

.new-user-upload-progress {
    border: 1px solid #eef4f7;
    height: 75px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background: #F4F8FA;
    border-radius: 3px;
    padding: 10px;
    justify-content: space-between;
}

.new-user-upload-file {
    font-size: 28px;
    color: #097C05;
}

.new-user-file-name {
    margin-left: 10px;
    font-size: 16px;
}

.new-user-upload-label {
    align-items: center;
    display: flex;
}

.new-user-status {
    font-size: 18px;
    margin-right: 5px;
    color: blue;
}

.new-user-status-wrp {
    margin-right: 5px;
    align-items: center;
    display: flex;
}

.new-user-status-wrp.success, .new-user-status.success  {
    color: #09B95B;
}

.new-user-status-wrp.error, .new-user-status.error  {
    color: #E83034;
}

.new-user-repload-wrp{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;

    button {
        color: #104EA1;
        font-weight: bold;
    }
}

.add-user-form-wrp {
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    @media (max-width: 575px) {
        .ant-col-12{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

.btn-bordered {
    border: 1px solid #E2E8F0;
}

.admin-modal-title{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.005em;
}

.admin-modal-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt'
}

.admin-modal-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-modal-content {
    border-radius: 6px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}