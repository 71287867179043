.banner-body {
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  width: 25rem;
  text-align: center;
  height: 5rem;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.side-menu {
  position: sticky;
  display: flex;
  justify-content: space-between;
  max-height: 100vh;
  top: 0;
  overflow: hidden;
  > div{
    overflow-y: auto;
    width: calc(100% + 20px);
    margin-right: -20px;
    padding-bottom: 1rem;
  }
}

.menu-links {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 18px;
  justify-content: space-between;
  width: 110px;
}