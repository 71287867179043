.bs-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.bs-title-img-wrapper {
  margin-right: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  cursor: pointer;
  min-height: 65px;
  min-width: 130px;
  max-height: 65px;
  max-width: 130px;
}

.bs-thumb-img-wrapper {
  border-radius: 5px;
  cursor: pointer;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative !important;
}

.bs-title-img {
  width: 130px;
  height: 65px;
  object-fit: cover;
  overflow: hidden;
  max-width: 130px;
  max-height: 65px;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: center;
  min-height: 65px;
}

.bs-title-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #2d3748;
}

.bs-sub-title-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2d3748;
  margin-top: 10px;
}

.bs-lnk {
  text-decoration: none !important;
  margin-left: 5px;
}

.bs-br-btn {
  text-decoration: none;
  text-align: center;
  height: 40px;
  padding-top: 8px;
  border-radius: 6px;
  border: 1px solid $trnsp;
  min-width: 100px;
  color: $wht;
  background-color: $main-blue-100;
  border-color: $main-blue-100;
  margin-left: 10px;
  &:hover,
  &:focus {
    color: $wht;
    background-color: $main-blue-100;
    border-color: $main-blue-100;
  }
}

.bs-rw {
  height: 100%;
  align-items: center;
}

.bs-dcln-mdl-ttl {
  text-align: center;
  font-weight: 700;
}

.bs-dcln-rw {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bs-dcln-txt {
  border: $dark-30 solid 2px;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
}

.bs-dcln-btn-rw {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.bs-txt-wrppr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.bs-tbl-stts-rw {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bs-calendar {
  // max-height: 50vh;
}

.bs-calendar .rbc-day-slot .rbc-events-container {
  margin-right: unset;
}

.bs-calendar .rbc-day-slot .rbc-events-container .single-line {
  display: flex;
  flex-flow: wrap;
  .rbc-event-content{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bs-calendar .unavailable-event {
  background-image: repeating-linear-gradient(-45deg, transparent 0 6px, #718096 7px 8px);
  background-color: unset;
  border: unset;
}

.bs-calendar .unavailable-event .rbc-event-content {
  padding: 0;
  width: max-content;
  max-height: 18px;
  background: #edf2f7;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  font-feature-settings: "salt" on;

  display: flex;
  align-items: center;

  color: #515d71;
}

.bs-calendar .unavailable-event .rbc-event-label {
  color: transparent;
}

.bs-calendar .new-event, .pending-event, .bs-calendar .pending-event-selected {
  background: rgba(16, 78, 161, 0.1);
  border: 1px solid #4071b4 !important;
  box-sizing: border-box;
  border-radius: 3px;
  color: #104ea1;
  .rbc-event-content{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bs-calendar .new-event-success, .approved-event, .bs-calendar .approved-event-selected {
  background: #e8f8ec;
  border: 1px solid #09b95b !important;
  box-sizing: border-box;
  border-radius: 3px;
  color: #1d8b50;
  .rbc-event-content{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.bs-calendar .pending-event-selected {
  border: 3px solid #4071b4;
}

.bs-calendar .approved-event-selected {
  border: 3px solid #09b95b;
}

.bs-calendar .rbc-allday-cell {
  display: none;
}

.bs-calendar .day-col {
  background-color: #edf2f7;
}

.bs-calendar .slot-cell {
  border: unset;
}

.bs-calendar .rbc-time-gutter {
  background-color: #edf2f7;
}

.bs-calendar-date-row {
  align-items: center;
  height: 53px;
  background: #ffffff;
  box-shadow: 0px 1px 0px #dce3ed;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #131720;
}

.bs-calendar-view-selector-row {
  height: 35px;
  border-bottom: 1px solid #e2e8f0;
  margin-top: 20px;
}

.bs-calendar-view-btn {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #718096;
}

.bs-calendar-view-btn-selected,
.bs-calendar-view-btn-selected:active,
.bs-calendar-view-btn-selected:focus {
  color: #104ea1;
  border-bottom: 2px solid #104ea1;
  background: unset;
}

.bs-booking-modal {
  padding: 0;
}

.bs-booking-modal .title-row {
  padding-top: 40px;
  margin-left: 30px;
  align-items: center;
}

.bs-booking-modal .space-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #104ea1;
  margin-right: 12px;
}

.bs-booking-modal .book-space {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #2d3748;
  margin-left: 12px;
}

.bs-booking-modal .reason-row {
  height: 60px;
  margin-left: 30px;
  margin-right: 30px;
}

.bs-booking-modal .reason-row input {
  border-left: unset;
  border-right: unset;
  border-top: unset;
  border-bottom: 1px solid #e2e8f0;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #2d3748;
}

.bs-booking-modal .row-details{
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;

  font-family: Inter;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #2d3748;
}

.bs-booking-modal .reason-row-details {
  font-weight: 600;
}

.bs-booking-modal .date-time-row {
  height: 70px;
  margin-left: 30px;
  margin-right: 30px;
  align-content: center;
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;
}

.bs-booking-modal .date-time-row .date-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #2d3748;
  margin-left: 5px;
}

.bs-booking-modal .date-time-row .time-text,
.bs-time-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #104ea1;
  text-align: end;
}

.bs-booking-modal .validations-div {
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 20px;
}

.bs-booking-modal .validation-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #718096;
  margin-left: 10px;
}

.bs-booking-modal .validation-text-error {
  color: #e83034 !important;
}

.bs-booking-modal .confirm-button {
  width: 460px;
  height: 50px;
  margin-left: 27px;
  margin-right: 27px;
  margin-top: 25px;
  margin-bottom: 20px;
  border-radius: 6px;
  background: #104ea1;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #ffffff;
}

.bs-booking-modal .confirm-button[disabled] {
  background: #cbd5e0;
  color: #718096;
}

.bs-grid-search-box {
  width: 250px !important;
}

.bs-number-input > input {
  height: unset !important;
  padding: unset !important;
}

.bs-add-space-mdl {
  font-family: Inter;
  font-style: normal;
}

.bs-add-space-mdl .ant-form-item {
  margin-bottom: 1rem;
}

.bs-add-space-mdl .ant-form-item-has-error {
  margin-bottom: 0rem;
}

.bs-add-space-mdl .input-chk-bx {
  margin-bottom: unset;
}

.bs-add-space-mdl .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #2d3748;
}

.bs-add-space-mdl .img-upload {
  margin-top: 30px;
}

.bs-add-space-mdl .upload-dragger {
  width: 355px;
  height: 215px;
  background: #ffffff;
  border: 1px dashed #cbd5e0;
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  margin-top: 15px;
  cursor: pointer;
}
.bs-add-space-mdl .upload-dragger:hover {
  border-color: #40a9ff;
}

.bs-add-space-mdl .thumb-upload {
  width: 200px;
  height: 112.5px;
  background: #ffffff;
  border: 1px dashed #cbd5e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.bs-add-space-mdl .intnnt-btn-rw {
  position: absolute;
  bottom: 30px;
}

.bs-add-space-mdl .intnnt-clmn {
  padding-top: 40px;
  padding-bottom: unset;
}

.bs-add-space-mdl .ant-upload-picture-card-wrapper {
  background: #ffffff;
  border: 1px dashed #e2e8f0;
  border-radius: 4px;
  padding: 4px;
  margin-top: 15px;
}

.bs-add-space-mdl .ant-upload-list-picture-card .ant-upload-list-item {
  padding: unset;
}

.bs-add-space-mdl .ant-upload-list-picture-card-container,
.bs-add-space-mdl .ant-upload-select-picture-card {
  margin: 3px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
}
.bs-add-space-mdl .facility-select .ant-select-selector{
  height: unset !important;
}

.bs-details-modal {
  font-family: Inter;
  font-style: normal;
}

.bs-details-modal .title-row {
  margin-top: 40px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 26px;
}

.bs-details-modal .title-row .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.005em;
  color: #2d3748;
}

.bs-details-modal .title-row .book-space {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  font-feature-settings: "salt" on;
  color: #104ea1;
}

.bs-details-modal .ant-modal-close-x {
  margin-right: 20px;
  margin-top: 20px;
  width: unset;
  height: unset;
  line-height: unset;
}

.bs-details-modal .details-div {
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.bs-details-modal .facilities-row {
  padding-top: 15px;
  border-top: 1px solid #e2e8f0;
}

.bs-details-modal .description {
  padding-top: 10px;
  margin-bottom: 15px;
  border-top: 1px solid #e2e8f0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-feature-settings: "salt" on;
  color: #2d3748;
}

.bs-details-modal .detail-row {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #515d71;
}

.bs-input-grp-left {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.bs-input-grp-right .ant-select-selector {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}
.bs-tab-selector {
  background: #edf2f7;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
}

.bs-tab-selector .ant-radio-button-wrapper {
  border: none;
  height: 100%;
  background: none;
  color: #718096;
  padding-top: 4px;
}
.bs-tab-selector .ant-radio-button-wrapper::before, .bs-tab-selector .ant-radio-button-wrapper:hover:before  {
  background: none;
}

.bs-tab-selector .ant-radio-button-wrapper-checked {
  background: #FFFFFF;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  color: #104EA1;
}
