.intnnt-mdl {
  padding-bottom: 0;
}
.intnnt-drk-10 {
  background-color: #f4f8fa;
}
.intnnt-rw{
  display: flex;
  @media(max-width: 599px) {
    display: block !important;
  }
}
.intnnt-clmn {
  padding: 60px;
  flex: 0.5;
  &.intnnt-clmn-left{
    padding-right: 30px;
  }
  &.intnnt-clmn-right{
    padding-left: 30px;
  }
  @media(max-width: 599px) {
    padding: 30px;
    &.intnnt-clmn-left{
      padding-right: 30px;
    }
    &.intnnt-clmn-right{
      padding-left: 30px;
    }
  }
}
.intnnt-spc {
  width: 100%;
  > .ant-space-item{
    width: 100%;
  }
}
.intnnt-upld {
  display: block;
  width: 100%;
  padding-bottom: 10px;
}
.intnnt-drggr {
  width: 100%;
  background: #fff !important;
  border-radius: 6px !important;
}
.intnnt-upld-thmbnl {
  width: 50%;
  height: 50%;
}
.intnnt-drggr-thmbnl {
  background: #fff !important;
  padding: 0 10px 0 10px;
  border-radius: 6px !important;
}
.intnnt-sbttl {
  margin-bottom: 0 !important;
  font-weight: 700;
  text-align: center;
  padding-top: 5px;
}
.intnnt-mrgn-tp-40 {
  margin-top: 40px;
}
.intnnt-npt {
  width: 100%;
  max-width: 100%;
  height: 42px;
  border-radius: 6px !important;
}
.ant-select-selector {
  border-radius: 6px !important;
  height: 42px !important;
  justify-content: center;
  padding: 5px 11px !important;
}
.intnnt-btn-rw {
  width: 100%;
  margin-top: 40px;
  justify-content: space-between;
  .link-btn-wrapper{
    display: flex;
    align-items: center;
  }
}
.intnnt-btn {
  width: 100px;
  height: 42px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  padding: 0 12px 0 12px;
}
.intnnt-btn-dngr {
  color: #e83034;
}
.intnnt-btn-cncl {
  color: #104ea1;
  padding-left: 0;
  text-align: left !important;
}
.intnnt-btn-prmry {
  color: #104ea1;
}
.intnnt-btn-prmry-fll {
  background: #104ea1;
  border-color: #104ea1;
}
.intnnt-btn-upld {
  width: 150px;
  background: #104ea1 !important;
  border-color: #104ea1 !important;
}
.intnnt-mn-btn-rw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 480px) {
  .intnnt-mn-btn-rw {
    flex-direction: column;
  }
}
.ant-space-vertical {
  display: flex;
}
.intnnt-prgrss-crd {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border-style: dashed;
}
.intnnt-prgrss-fl-nm {
  font-weight: 600;
  margin-top: 20px;
  color: #104ea1;
}
.intnnt-prgrss-fl-sz {
  color: #104ea1;
}
.intnnt-cncl-cntnr {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.intnnt-bldng-nm {
  margin-bottom: 0;
  font-weight: 600;
}
.intnnt-bldng-lctn {
  margin-bottom: 0;
  font-size: 12px;
  color: #718096;
}
.intnnt-ppvr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
}
.intnnt-ppvr-btn {
  width: 100%;
  text-align: start;
}
.intnnt-ppvr-btn:hover {
  background-color: #f4f8fa;
  color: #104ea1;
}
.intnnt-ntfcn-btn-rw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ind-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.ind-title-img-wrapper {
  margin-right: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  cursor: pointer;
  min-height: 50px;
  min-width: 100px;
  max-height: 50px;
  max-width: 100px;
}
.ind-thumb-img-wrapper {
  border-radius: 5px;
  cursor: pointer;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative !important;
}

.ind-title-img {
  width: 100px;
  height: 50px;
  object-fit: cover;
  overflow: hidden;
  max-width: 100px;
  max-height: 50px;
  text-overflow: ellipsis;
  font-size: 12px;
  text-align: center;
  min-height: 50px;
}

.ind-title-img.place-holder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ind-thumb-img-preview {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    0deg,
    rgba(45, 55, 72, 0.70),
    rgba(45, 55, 72, 0.70)
  );
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.ind-thumb-img-preview:hover {
  opacity: 1;
}

.ind-title-on-video-preview {
  width: 100px;
  height: 50px;
  background: linear-gradient(
    0deg,
    rgba(45, 55, 72, 0.85),
    rgba(45, 55, 72, 0.85)
  );
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.ind-title-on-video-preview:hover {
  opacity: 1;
}

.ind-more {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ind-video-player-modal .ant-modal-body {
  padding: 0 !important;
}

.ind-video-player {
  background: #222;
}

.ind-video-player-modal .ant-modal-close {
  color: white;
  width: 40px;
  height: 40px;
  border: 1px solid #444;
  background: #444;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -35px;
  font-size: 18px;
}

.ind-video-footer {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .ind-video-footer-col{
    h5{
      span{
        margin-right: 5px;
      }
    }
    .ind-video-date{
      > span{
        margin-right: 5px;
      }
    }
  }
}

.fill{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
  }
}

.cloud-icon{
  color: #CBD5E0 !important;
}

.img-wrapper-holder {
  display: flex;
  flex-direction: column;
}