  // Starting map Thumbnail overlay styles
  .db-box{
    font-family: 'PT Sans', sans-serif;
    text-align: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  
  .db-box:before,
  
  .db-box:after{
    content: '';
    background: #1a1918;
    width: 100%;
    height: 0;
    opacity: 0.1;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.4s ease-in-out;
  }
  
  .db-box:after{
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
  }
  
  .db-box:hover:before,
  
  .db-box:hover:after{
    width: 100%;
    height: 100%;
    opacity: 0.4;
    cursor: pointer;
  }
  
  .db-box img{
    width: 100%;
    height: auto;
    opacity: 1;
    transition: all 0.6s ease;
  }
  
  .db-box:hover img{ filter: hue-rotate(50deg); }
  
  .db-box .box-content{
    color: #fff;
    width: 100%;
    padding: 0 20px;
    opacity: 0;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 70%;
    left: 50%;
    z-index: 2;
    transition: all .5s ease;
  }   
  
  .db-box:hover .box-content{
    opacity: 1;
    top: 50%;
  }
  
  .db-box .post{
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 11px;
    display: block;
    color: white;
    font-weight: 600;
  }
  
  
  @media only screen and (max-width:990px){
    .db-box{ margin: 0 0 30px; }
  }
// End map Thumbnail overlay styles

  .db-desk-marker {
    position: absolute;
    z-index: 2;
  }

  .db-desk-marker-sm {
    position: absolute;
    z-index: 2;
  }

  .db-floor-spinner {
    width: 100%;
    margin-top: 50px
  }

  .db-floor-img-wrapper {
    position: relative; 
    width: '650px'; 
  }

  .db-map-icon {
    text-align-last: 'right'
  }

  .db-map-legend {
    font-weight: bold; 
    font-size: 9pt; 
    margin-left: -8px;
  }

  .db-map-legend-wrapper {
    margin-top: -12px;
  }

  .map-alert {
    margin: '20px 0 20px 0'
  }

  .db-floor-select {
    width: 100%;
  }

  .db-available-button {
    margin-bottom: 15px; 
    margin-top: 15px; 
    float: right
  }

  .db-thumb-map-resize {
    max-height: 250px;
    overflow:auto;
  }

  .db-map-resize {
    max-height: 450px;
    overflow:auto;
  }

  .db-floor-resize {
    max-height: 300px;
    overflow:auto;
  } 