$fnt-1: 'Inter', sans-serif;

// Common colors
$trnsp: transparent;
$wht: #ffffff;
$wht-3: rgba(255, 255, 255, 0.3);
$gry-fa: #fafafa;
$gry-f8: #f8f8f8;
$gry-f2: #f2f2f2;
$gry-f0: #f0f0f0;
$gry-e: #eeeeee;
$gry-e5: #e5e5e5;
$gry-e-2: rgba(238, 238, 238, 0.2);
$gry-e-3: rgba(238, 238, 238, 0.3);
$gry-d: #dddddd;
$gry-d9: #d9d9d9;
$gry-c: #cccccc;
$gry-b: #bbbbbb;
$gry-a: #aaaaaa;
$gry-9: #999999;
$gry-8: #888888;
$gry-6: #666666;
$gry-4: #444444;
$gry-3: #333333;
$gry-1: #111111;
$blck: #000000;
$blck-25: rgba(0, 0, 0, 0.25);
$blck-5: rgba(0, 0, 0, 0.5);
$blck-75: rgba(0, 0, 0, 0.75);

// Custom colors
$main-blue-100: #104ea1;
$main-blue-2: #425466;
$main-blue-3: #CBD5E0;
$custom-blue-info: #eaf4ff;
$custom-blue-info-stroke: #c1dbf8;
$custom-red-1: #ec1e28;
$custom-red-error: #e83034;
$custom-orange-1: #e88b00;
$custom-orange-2: #f0a433;

$custom-green-1: #1dc14b;

$dark-10: #f4f8fa;
$dark-20: #edf2f7;
$dark-30: #e2e8f0;
$dark-60: #718096;
$dark-70: #515D71;
$dark-80: #2D3748;
$bg: #f7fafc;