.news-feed-title-wrapper {
    display: flex;
}

span.news-feed-title-draft {
    color: red;
    margin-right: 10px;
}
.new-post{
    main.ant-layout-content{
        margin-right: 20px;
        + aside{
            max-width: 350px !important;
            min-width: 350px !important;
            flex: 0 0 350px !important;

            .ant-picker{
                width: 100%;
                max-width: 350px;
            }
        }
    }
    .row-controls{
        justify-content: space-between;
    }
}
.layout--mobile{
    .new-post{
        flex-direction: column-reverse;
        main.ant-layout-content{
            width: 100%;
            margin-right: 0;
            + aside{
                max-width: 100% !important;
                min-width: 100% !important;
                flex: 0 0 100% !important;
            }
        }
    }
}

.ind-feature-img-preview {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: linear-gradient(
    0deg,
    rgba(45, 55, 72, 0.70),
    rgba(45, 55, 72, 0.70)
  );
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.ind-feature-img-preview:hover {
    opacity: 1;
}