*{
    font-family: $fnt-1;
}
h1,h2,h3,h4,h5,label{
    color: $dark-80;
    &.ant-typography{
        color: $dark-80;
    }
}
p,a,section{
    color: $dark-80;
}
.menu-logo {
    margin: 30px 18px 8px;
    width: calc(50% - 18px);
    img{
        width: 100%;
    }
}
.menu-int-logo{
    margin: 30px 18px 8px;
    width: 100%;
    max-width: 100px;
}
.ant-layout{
    background-color: $bg;
    .ant-layout-header{
        border-bottom: 1px solid $dark-30;
        padding: 0 30px;
        text-align: right;
        .login-info{
            .profile-link{
                text-decoration: none;
                color: inherit;
                font-weight: 600;
            }
            span{
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background-color: $custom-red-1;
                vertical-align: middle;
                margin-left: 0.5rem;
                text-align: center;
                color: $wht;
                line-height: 40px;
                font-size: 16px;
                font-weight: bold;
                margin-top: -5px;
            }
        }
    }
    .ant-layout-sider {
        background: $wht;
        min-height: 100vh;
        border-right: 1px solid $dark-30;
    }
    .ant-menu-item{
        color: $main-blue-2;
        font-size: 14px;
        margin: 24px 0 !important;
        padding-left: 18px !important;
        height: 24px;
        line-height: 24px;
        a{
            color: inherit;
            font-size: inherit;
            font-weight: 600;
            .icon{
                margin-right: 10px;
            }
        }
        &.ant-menu-item-selected, 
        &.ant-menu-item-active{
            background-color: $trnsp;
            color: $main-blue-100;
            a{
                color: inherit;
                .icon{
                    &.icon-users{
                        background-position-x: 11.11%;
                        background-position-y: 0%;
                    }
                    &.icon-news-feed{
                        background-position-x: 11.11%;
                        background-position-y: 33.33%;
                    }
                    &.icon-booking-space{
                        background-position-x: 99.99%;
                        background-position-y: 33.33%;
                    }
                    &.icon-desk-booking{
                        background-position-x: 99.99%;
                        background-position-y: 99.99%;
                    }
                    &.icon-play-video{
                        background-position-x: 11.11%;
                        background-position-y: 66.66%;
                    }
                    &.icon-building{
                        background-position-x: 11.11%;
                        background-position-y: 99.99%;
                    }
                    &.icon-issues{
                        background-position-x: 99.99%;
                        background-position-y: 66.66%;
                    }
                }
            }
            &:after{
                right: initial;
                left: 0;
                border-color: $main-blue-100;
                border-width: 2px;
            }
        }
        .ant-menu-item-icon{
            margin-right: 14px;
            font-size: 16px;
            color: inherit;
            vertical-align: text-top;
        }
    }
    .grid-search-box{
        width: 400px;
    }
    .grid-search-box-md{
        width: 200px;
    }
    .grid-options{
        width: 100%;
        display: flex;
        justify-content: space-between;
        > .ant-space{
            margin-bottom: 8px;
            > .ant-space-item{
                margin-bottom: 8px;
            }
        }
        .grid-options--search{
            .ant-space-item{
                &:first-child{
                    width: calc(100% - 125px);
                    max-width: 400px;
                }
            }
        }
    }
    .main-content-wrapper{
        padding: 24px 30px;
    }
    &.layout--mobile{
        .main-content-wrapper{
            padding: 24px 16px;
        }
        .ant-layout-header{
            display: flex;
            justify-content: space-between;
            padding: 0 16px;
        }
        .grid-search-box{
            width: 100%;
        }
        .grid-options{
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            > .ant-space{
                width: 100%;
                justify-content: center;
                @media (max-width: 400px) {
                    flex-wrap: wrap;
                }
            }
            .grid-options--search{
                .ant-space-item{
                    &:first-child{
                        width: calc(100% - 125px);
                        max-width: 400px;
                    }
                    @media (max-width: 400px) {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .btn--primary{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.ant-drawer{
    &.side-drawer{
        .ant-drawer-content{
            position: sticky;
            display: flex;
            justify-content: space-between;
            max-height: 100vh;
            top: 0;
            overflow: hidden;
            > div{
              overflow-y: auto;
              width: calc(100% + 20px);
              margin-right: -20px;
              padding-bottom: 2rem;
            }
            .ant-drawer-body{
                padding: 0;
            }
            .ant-menu-item{
                color: $main-blue-2;
                font-size: 14px;
                margin: 24px 0 !important;
                padding-left: 18px !important;
                height: 24px;
                line-height: 24px;
                a{
                    color: inherit;
                    font-size: inherit;
                    font-weight: 600;
                    .icon{
                        margin-right: 10px;
                    }
                }
                &.ant-menu-item-selected{
                    background-color: $trnsp;
                    color: $main-blue-100;
                    a{
                        color: inherit;
                        .icon{
                            &.icon-users{
                                background-position-x: 11.11%;
                                background-position-y: 0%;
                            }
                            &.icon-news-feed{
                                background-position-x: 11.11%;
                                background-position-y: 33%;
                            }
                            &.icon-booking-space{
                                background-position-x: 99.99%;
                                background-position-y: 33.33%;
                            }
                            &.icon-desk-booking{
                                background-position-x: 99.99%;
                                background-position-y: 99.99%;
                            }
                            &.icon-play-video{
                                background-position-x: 11.11%;
                                background-position-y: 66%;
                            }
                            &.icon-building{
                                background-position-x: 11.11%;
                                background-position-y: 99%;
                            }
                            &.icon-issues{
                                background-position-x: 99.99%;
                                background-position-y: 66.66%;
                            }
                        }
                    }
                    &:after{
                        right: initial;
                        left: 0;
                        border-color: $main-blue-100;
                        border-width: 2px;
                    }
                }
                .ant-menu-item-icon{
                    margin-right: 14px;
                    font-size: 16px;
                    color: inherit;
                    vertical-align: text-top;
                }
            }
        }
    }
}

.table-wrapper{
    .ant-table{
        .ant-table-container{
            width: 100%;
            overflow-x: auto;
            .ant-table-content{
                min-width: 1000px;
                width: 100%;
                .ant-table-cell{
                    .ant-badge-status{
                        white-space: nowrap;
                    }
                    .anticon-star{
                        width: 0;
                    }
                }
            }
        }
    }
}

.ant-select{
    &.ant-select-single{
        .ant-select-selector{
            height: 40px;
            padding: 5px 20px 5px 12px;
            border-radius: 6px;
            min-width: 150px;
        }
    }
}
.ant-input-search, .grid-search-box{
    max-width: 100%;
    .ant-input-group.ant-input-wrapper{
        .ant-input-affix-wrapper{
            height: 40px;
            padding: 8px 20px 8px 12px;
            border-radius: 6px !important;
        }
        .ant-input-group-addon{
            .ant-input-search-button{
                height: 38px;
                border-color: $trnsp;
                background-color: $trnsp;
                border-radius: 0 6px 6px 0;
                margin-left: -35px;
                position: relative;
                z-index: 1;
            }
            &:last-child{
                left: 0;
            }
        }
    }
    &.ant-input-affix-wrapper{
        height: 40px;
        padding: 8px 20px 8px 12px;
        border-radius: 6px !important;
    }
    .ant-input-affix-wrapper{
        input{
            + .ant-input-suffix {
                z-index: 10;
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }
}

.btn{
    height: 40px;
    border-radius: 6px;
    border: 1px solid $trnsp;
    min-width: 100px;
    + .btn{
        margin-left: 0.5rem;
    }
    span{
        color: inherit;
    }
    &.btn--primary{
        color: $wht;
        background-color: $main-blue-100;
        border-color: $main-blue-100;
        &:hover, &:focus{
            color: $wht;
            background-color: $main-blue-100;
            border-color: $main-blue-100;
        }
    }
    &.btn--o-primary{
        color: $main-blue-100;
        background-color: $wht;
        border-color: $main-blue-3;
        &:hover, &:focus{
            color: $main-blue-100;
            background-color: $wht;
            border-color: $main-blue-100;
        }
    }
    &.btn--info{
        color: $wht;
        background-color: $main-blue-100;
        border-color: $main-blue-100;
        &:hover, &:focus{
            color: $wht;
            background-color: $main-blue-100;
            border-color: $main-blue-100;
        }
    }
    &.btn--warning{
        color: $wht;
        background-color: $custom-orange-1;
        border-color: $custom-orange-1;
        &:hover, &:focus{
            color: $wht;
            background-color: $custom-orange-1;
            border-color: $custom-orange-1;
        }
    }
    &.btn--danger{
        color: $wht;
        background-color: $custom-red-1;
        border-color: $custom-red-1;
        &:hover, &:focus{
            color: $wht;
            background-color: $custom-red-1;
            border-color: $custom-red-1;
        }
    }
    &.btn--text{
        height: initial;
        color: inherit;
        background-color: $wht;
        border: none;
        width: 100%;
        text-align: left;
        padding: 0 0.5rem;
        &:hover, &:focus{
            color: inherit;
            background-color: $dark-30;
            border: none;
        }
    }
}

.btn[disabled] {
    color: $gry-6 !important;
    background-color: $gry-d !important;
    border: $gry-d !important;
}

.link-btn{
    display: inline-block;
    height: initial;
    text-decoration: none;
    background: $trnsp;
    margin: 0 0.5rem;
    padding: 0;
    border: none;
    border-bottom: 1px solid $trnsp;
    span{
        color: inherit;
    }
    &:hover, &:focus{
        background: $trnsp !important;
    }
    &.link-btn--default{
        color: $dark-70;
        &:hover, &:focus{
            color: $dark-70;
            border-color: $dark-70 !important;
        }
    }
    &.link-btn--danger{
        color: $custom-red-error;
        &:hover, &:focus{
            color: $custom-red-error;
            border-color: $custom-red-error !important;
        }
    }
}
.ant-space-item{
    > input.ant-input{
        border-color: $dark-30;
        min-height: 40px;
        border-radius: 6px;
    }
}

.ant-picker{
    border-color: $dark-30;
    min-height: 40px;
    border-radius: 6px;
}

.quill{
    .ql-toolbar{
        &.ql-snow{
            border-color: $dark-30;
            border-radius: 6px 6px 0 0;
        }
    }
    .ql-container{
        &.ql-snow{
            border-color: $dark-30;
            border-radius: 0 0 6px 6px;
        }
    }
}

.status{
    white-space: nowrap;
    &:before{
        content: '';
        position: relative;
        display: inline-block;
        line-height: inherit;
        width: 10px;
        height: 10px;
        border-radius: 20px;
        margin-right: 2px;
    }
    &.status--active,
    &.status--published{
        &:before{
            background-color: $custom-green-1;
        }
    }
    &.status--inactive,
    &.status--scheduled{
        &:before{
            background-color: $custom-orange-2;
        }
    }
}

.ant-form-item-explain-error{
    color: $custom-red-error;
}

.select-text{
    color: $dark-70;
    display: inline-block;
    padding: 0 0.5rem;
}

.divider-vertical{
    display: inline-block;
    border-left: 1px solid $gry-d9;
    height: 50px;
    margin: -20px 0.5rem;
}

.ant-table-thead{
    tr{
        th{
            color: #718096;
            font-weight: 400;
        }
    }
}

.ant-table-wrapper{
    background: $wht;
    border: 1px solid $gry-d9;
    border-radius: 12px;
    overflow: hidden;
    .ant-table{
        border-bottom: 1px solid $gry-d9;
        .ant-table-title{
            padding: 0;
            background-color: $dark-20;
            .ant-space{
                padding: 1rem;
            }
        }
        .ant-table-container{
            .ant-table-content{
                table{
                    th {
                        background-color: $wht;
                        border-bottom: 1px solid $gry-d9;
                        &.ant-table-selection-column{
                            padding-left: 20px;
                        }
                    }
                    tr{
                        td{
                            background-color: $wht;
                            &.ant-table-selection-column{
                                padding-left: 20px;
                            }
                        }
                        &:nth-child(2n - 1) {
                            td{
                                background-color: $dark-10;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-pagination{
        margin: 12px;
        .ant-pagination-item{
            border: none;
            background-color: $dark-10;
            a{
                color: $dark-60;
            }
            &.ant-pagination-item-active{
                background-color: $main-blue-100;
                a{
                    color: $wht;
                }
            }
        }
        .ant-pagination-prev, .ant-pagination-next{
            .ant-pagination-item-link{
                border: none;
                background-color: $dark-10;
                color: $dark-60;
            }
        }
    }
}

.loading-wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-popover{
    .ant-popover-inner{
        .ant-popover-inner-content{
            padding: 8px;
        }
    }
}

.ant-card{
    margin-bottom: 1.5rem;
    .ant-card-head{
        .ant-card-head-title{
            font-weight: 600;
        }
    }
}

.more-settings {
    font-size: 28px;
    font-weight: bold;
}

.tenant-col {
    max-width: 130px;
    display: block;
}

.ant-form{
    .ant-form-item{
        margin-bottom: 1.5rem;
        input[type=text], 
        input[type=password], 
        input[type=email], 
        input[type=number] {
            height: 40px;
            padding: 5px 20px 5px 12px;
            border-radius: 6px;
        }
    }
}
.ant-input-group{
    .ant-input-group-addon{
        border-radius: 6px 0 0 6px;
        + input{
            border-radius: 0 6px 6px 0 !important;
        }
    }
}
.ant-input-password{
    padding: 0 0 0 0;
    border-radius: 6px;
    > .ant-input{
        + .ant-input-suffix{
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            background: $trnsp;
            top: 0;
            right: 0;
            width: 40px;
            height: 100%;
            vertical-align: middle;
            margin: 0;
        }
    }
}

.alert{
    padding: 1rem 1.5rem;
    border-radius: 6px;
    border: 1px solid $trnsp;
    margin-bottom: 1.5rem;
    &.alert-info{
        background-color: $custom-blue-info;
        color: $main-blue-100;
        border-color: $custom-blue-info-stroke;
    }
}
.icon{
    display: inline-block;
    background-size: 1000%;
    background-repeat: no-repeat;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-position-x: 0%;
    background-position-y: 0%;
    margin-top: -2px;
    // @include transition(0.2s);
    &.icon-users{
        background-position-x: 0%;
        background-position-y: 0%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-cleaner{
        background-position-x: 22.22%;
        background-position-y: 0%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 33.33%;
            }
        }
    }
    &.icon-anchor{
        background-position-x: 44.44%;
        background-position-y: 0%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 55.55%;
            }
        }
    }
    &.icon-delete-user{
        background-position-x: 66.66%;
        background-position-y: 0%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 77.77%;
            }
        }
    }
    &.icon-upload-cloud{
        background-position-x: 88.88%;
        background-position-y: 0%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 100%;
            }
        }
    }
    &.icon-news-feed{
        background-position-x: 0%;
        background-position-y: 33%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-booking-space{
        background-position-x: 88.88%;
        background-position-y: 33%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-desk-booking{
        background-position-x: 88.99%;
        background-position-y: 99.99%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-issues{
        background-position-x: 88.88%;
        background-position-y: 66%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-settings{
        background-position-x: 22.22%;
        background-position-y: 33%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 33.33%;
            }
        }
    }
    &.icon-trash{
        background-position-x: 44.44%;
        background-position-y: 33%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 55.55%;
            }
        }
    }
    &.icon-csv{
        background-position-x: 66.66%;
        background-position-y: 33%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 77.77%;
            }
        }
    }
    &.icon-play-video{
        background-position-x: 0%;
        background-position-y: 66%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-manager{
        background-position-x: 22.22%;
        background-position-y: 66%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 33.33%;
            }
        }
    }
    &.icon-edit-paper{
        background-position-x: 44.44%;
        background-position-y: 66%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 55.55%;
            }
        }
    }
    &.icon-star{
        background-position-x: 66.66%;
        background-position-y: 66%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 77.77%;
            }
        }
    }
    &.icon-building{
        background-position-x: 0%;
        background-position-y: 99%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 11.11%;
            }
        }
    }
    &.icon-security{
        background-position-x: 22.22%;
        background-position-y: 99%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 33.33%;
            }
        }
    }
    &.icon-edit-note{
        background-position-x: 44.44%;
        background-position-y: 99%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 55.55%;
            }
        }
    }
    &.icon-upload-image{
        background-position-x: 66.66%;
        background-position-y: 99%;
        &.icon-hovered{
            &:hover, &:focus{
                background-position-x: 77.77%;
            }
        }
    }
}

.intenant-confirm {
    .ant-modal-confirm-btns{
        display: flex;
        justify-content: center;
        float: unset !important;
    }
}

.ant-input-number-input { 
    height: 38px;
}

.menu-icon {
    margin-top: 4px
}

.ant-comment-inner {
    padding: 7px 0;
}
