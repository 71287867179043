.contact-number-dial-code{
    width: 90px !important;
    cursor: pointer !important;

    .ant-select-selector {
        min-width: 50px !important;
        cursor: pointer !important;
    }

    .ant-select-arrow{
        color: #222 !important;
        cursor: pointer !important;
    }

    input {
        height: 100% !important;
    }
}

.contact-number-field{
    .ant-input-group-addon {
        background-color: #fff !important;
    }   
}